<template>
	<div class="wrap">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="关键字">
					<el-input v-model="searchForm.search" placeholder="产品名称" @submit.native.prevent></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="loadData(1)">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="exportCustomer">导出</el-button> 
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="created_at" label="日期" width="100">
				<template slot-scope="scope">
					{{scope.row.created_at && scope.row.created_at.substring(0,10)}}
				</template>
			</el-table-column>
			<el-table-column prop="name" label="产品名称"></el-table-column>
			<el-table-column prop="sku" label="规格"></el-table-column>
			<el-table-column prop="last_month_left_num" label="上月结存数量"></el-table-column>
			<el-table-column prop="this_month_buy_num" label="本月购进数量"></el-table-column>
			<el-table-column prop="this_month_used_num" label="本月消耗数量"></el-table-column>
			<el-table-column prop="this_month_left_num" label="本月结存数量"></el-table-column>
			<el-table-column prop="price" label="零售价"></el-table-column>
			<el-table-column prop="this_month_used_price" label="本月消耗金额(元)"></el-table-column>
			<el-table-column prop="this_month_left_price" label="本月结存金额(元)"></el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: [],
				searchForm: {
					keyword: '',
					page: 1,
					page_size: 10
				},
				total: 0
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			
			loadData() {
				this.loading = true;
				this.$api.table.stockList(this.searchForm).then().then(res => {
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.total;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
			//导出数据
			exportCustomer(){
				this.$api.table.stockExport(this.searchForm).then(res => {
					let a = document.createElement('a');
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					});
					let objectUrl = URL.createObjectURL(blob);
					a.setAttribute("href", objectUrl);
					a.setAttribute("download", '出库存月报表导出.xls');
					a.click();
				})
			},
		}
	};
</script>
<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-right: 5px;
	}
</style>
